<template>
  <div />
</template>

<script>
export default {
  async mounted() {
    if (!this.$auth.user) {
      window.location.assign('/login')
    }
    function getQueryParameterByKey(key) {
      const pairStrings = window.location.search.slice(1).split('&')
      const pairs = pairStrings.map(pair => pair.split('='))
      return pairs.reduce((value, pair) => {
        if (value) return value
        return pair[0] === key ? decodeURIComponent(pair[1]) : null
      }, null)
    }

    function getSSORedirectURL(ssoToken) {
      const redirectURL = getQueryParameterByKey('redirect')
      const domain = getQueryParameterByKey('domain')
      if (redirectURL.indexOf('https://') !== 0 || !domain) {
        return null
      }

      return `https://app.hellonext.co/redirects/sso?domain=${domain}&ssoToken=${ssoToken}&redirect=${redirectURL}`
    }
    const ssoToken = await this.helloNextLogin()
    const redirectURL = getSSORedirectURL(ssoToken)
    if (redirectURL) {
      window.location.assign(redirectURL)
    }
  },
}
</script>

<style>

</style>
